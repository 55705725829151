import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/home/index.vue'

const routes: Array<RouteRecordRaw> = [
	{
		path: '/',
		name: 'home',
		component: HomeView
	},
	{
		path: '/articleDetails',
		name: 'articleDetails',
		component: () => import('../views/home/articleDetails.vue'),
	},
	{
		path: '/articleDetailsd',
		name: 'articleDetailsd',
		component: () => import('../views/home/articleDetailsd.vue'),
	},
	{
		path: '/admin',
		name: 'adminView',
		component: () => import('../views/admin/index.vue'),
		redirect: '/admin/panel',
		children: [
			{
				path: '/admin/companyPanel',
				name: 'companyPanel',
                meta: {
                    title: '公司面板',
                },
				component: () => import('../views/admin/companyPanel.vue')
			},
			{
				path: '/admin/panel',
				name: 'panel',
                meta: {
                    title: '产业情况',
                },
				component: () => import('../views/admin/panel.vue')
			},
			{
				path: '/admin/panel2',
				name: 'panel2',
                meta: {
                    title: '产业情况',
                },
				component: () => import('../views/admin/panel2.vue')
			},
			{
				path: '/admin/Industrial',
				name: 'Industrial',
                meta: {
                    title: '产业情况',
                },
				component: () => import('../views/admin/Industrial.vue') 
			},
			{
				path: '/admin/productData',
				name: 'productData',
                meta: {
                    title: '产品数据',
                },
				component: () => import('../views/admin/productData.vue') 
			},
			{
				path: '/admin/productData2',
				name: 'productData2',
                meta: {
                    title: '产品数据',
                },
				component: () => import('../views/admin/productData2.vue') 
			},
			{
				path: '/admin/productHistory',
				name: 'productHistory',
                meta: {
                    title: '产品历史数据',
                },
				component: () => import('../views/admin/productHistory.vue') 
			},
			{
				path: '/admin/IndustrialDetails',
				name: 'IndustrialDetails',
                meta: {
                    title: '资讯详情',
                },
				component: () => import('../views/admin/IndustrialDetails.vue') 
			},
			{
				path: '/admin/IndustrialDetailsIdx',
				name: 'IndustrialDetailsIdx',
                meta: {
                    title: '资讯详情',
                },
				component: () => import('../views/admin/IndustrialDetails2.vue') 
			},
			{
				path: '/admin/searchResults',
				name: 'searchResults',
                meta: {
                    title: '公司面板',
                },
				component: () => import('../views/admin/searchResults.vue')
			},
			{
				path: '/admin/IndustrialDataCenter/index',
				name: 'IndustrialDataCenter',
                meta: {
                    title: '产业数据中心',
                },
				// redirect: '/admin/IndustrialDataCenter/chinaOperate',
				component: () => import('../views/admin/IndustrialDataCenter/index.vue'),
				children: [
					{
						path: '/admin/IndustrialDataCenter/chinaOperate',
						name: 'chinaOperate',
						meta: {
							title: '中国运营数据',
						},
						component: () => import('../views/admin/IndustrialDataCenter/chinaOperate.vue')
					},
					{
						path: '/admin/IndustrialDataCenter/next3Years',
						name: 'next3Years',
						meta: {
							title: '未来3年产能变动',
						},
						component: () => import('../views/admin/IndustrialDataCenter/next3Years.vue')
					},
					{
						path: '/admin/IndustrialDataCenter/regionOperate',
						name: 'regionOperate',
						meta: {
							title: '区域运营数据',
						},
						component: () => import('../views/admin/IndustrialDataCenter/regionOperate.vue')
					},
					{
						path: '/admin/IndustrialDataCenter/cutOut',
						name: 'cutOut',
						meta: {
							title: '减停产增复产',
						},
						component: () => import('../views/admin/IndustrialDataCenter/cutOut.vue')
					},
					{
						path: '/admin/IndustrialDataCenter/operationBox',
						name: 'operationBox',
						meta: {
							title: '终端运行数据',
						},
						component: () => import('../views/admin/IndustrialDataCenter/operationBox.vue')
					},
					{
						path: '/admin/IndustrialDataCenter/ImportExport',
						name: 'ImportExport',
						meta: {
							title: '进口量进口额',
						},
						component: () => import('../views/admin/IndustrialDataCenter/ImportExport.vue')
					},
					{
						path: '/admin/IndustrialDataCenter/tradingNation',
						name: 'tradingNation',
						meta: {
							title: '主要贸易国',
						},
						component: () => import('../views/admin/IndustrialDataCenter/tradingNation.vue')
					}
				]
				
			}
		]
	},
]

const router = createRouter({
	history: createWebHistory(),
	routes,
	scrollBehavior(to, from, savedPosition){
		if (savedPosition) {
			return savedPosition
		} else {
			return { top: 0 }
		}
	}
})

export default router
