<template>
	<div class="header" :style="{position:positionFixed?'fixed':'sticky'}" :class="{ 'has-bg': isBackgroundVisible }">
		<div class="content">
			<div class="logo" @click.stop="onIndex()"></div>
			<div class="navBox">
				<div class="nav" v-for="(item,index) in indexNavTop" :key="index" :class="navIdx == index?'active':''" @click="onNav(item,index)">{{item.title}}</div>
			</div>
			<div class="rightBox">
				<div class="search">
					<el-input v-model="searchKey" :placeholder="languageData?.keyword_t"  @keyup.enter="searchResults()" />
					<img class="i" src="@/assets/i42.png" alt="" srcset="" @click="searchResults()">
				</div>
				<div class="btnBox">
					<div class="btn btn2">
						<img class="i" src="@/assets/i43.png" alt="" srcset="">
						<div class="t">Browse via Wechat</div>
						<div class="wxCode">
							<img class="img" src="@/assets/i35.png" alt="" srcset="">
						</div>
					</div>
					<div class="line"></div>
					<div class="btn" @click="getMessageDrawer">
						<img class="i" src="@/assets/i44.png" alt="" srcset="">
						<div class="t">Message</div>
						<div class="dian" v-if="totalMsgNo > 0"></div>
					</div>
				</div>
				<div class="userInfo">
					<div class="tbox">
						<div class="t">{{userInfo.company}}</div>
						<img class="i" src="@/assets/i45.png" alt="" srcset="">
					</div>
					<div class="moreInfo">
						<div class="box">
							<div class="ttop">
								<img class="i" src="@/assets/logo2.png" alt="" srcset="">
								<div class="t">Welcome to MagnetSearcher</div>
								<!-- <img class="c" src="@/assets/i46.png" alt="" srcset=""> -->
							</div>
							<div class="name">{{userInfo.company}}</div>
							<div class="itemBox">
								<div class="item" @click="companyPanel(1)">
									<img class="i" src="@/assets/i47.png" alt="" srcset="">
									<div class="t">Account</div>
								</div>
								<div class="item" @click="companyPanel(2)">
									<img class="i" src="@/assets/i48.png" alt="" srcset="">
									<div class="t">Device</div>
								</div>
								<div class="item" @click="companyPanel(3)">
									<img class="i" src="@/assets/i49.png" alt="" srcset="">
									<div class="t">Renewal</div>
								</div>
							</div>
							<div class="out" @click="loginOut()">
								<img class="i" src="@/assets/i50.png" alt="" srcset="">
								<div class="t">Log out</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="navBoxChild" v-if="indexNavTop.length > 0 && navShow" :class="{ 'has-bg2': isBackgroundVisible }">
			<div class="item" :class="navChildIdx == null || navChildIdx == 'null'?'active':''" @click="onNavChild(null,{id:null})">Home</div>
			<div class="item" v-for="(item,index) in indexNavTop[navIdx]?.children" :class="navChildIdx == item.id?'active':''" @click="onNavChild(index,item)">{{item.title}}</div>
		</div>
		<div class="messageDrawer">
			<el-drawer class="messageDrawer1" v-model="messageDrawer" :z-index="9999999" size="480" :show-close="false" :lock-scroll="false">
				<template #header>
					<div class="titleMessage">
						<div class="t">{{languageData.index_70}}</div>
						<div class="n">（{{totalMsgNo}}{{ languageData.index_71 }}）</div>
						<img class="i" src="@/assets/i38.png" alt="" srcset="" @click="messageDrawerClose()">
					</div>
				</template>
				<template #default>
					<el-scrollbar height="100%" ref="listBoxMsg" @scroll="handleScrollMsg" :always="false">
						<div class="listBox" v-if="msgListData.length > 0">
							<div class="list" v-for="(item,index) in msgListData" :key="index" @click="getmessageDrawerDetail(item)">
								<div class="imgBox">
									<img class="i" src="@/assets/i98.png" alt="" srcset="" v-if="item.is_read == 1">
									<img class="i" src="@/assets/i99.png" alt="" srcset="" v-else>
								</div>
								<div class="info" :class="item.is_read == 1?'no':''">
									<div class="name">{{item.title}}</div>
									<div class="msg">{{item.content}}</div>
								</div>
							</div>
						</div>
						<div class="noData" v-else>
							<img class="i" src="@/assets/quesheng.png" alt="" srcset="" >
							<div class="t">{{languageData.index_72}}</div>
						</div>
					</el-scrollbar>
					
				</template>
				<template #footer>
					<div class="btnBox" @click="msgAllRead">
						<img class="i" src="@/assets/i101.png" alt="" srcset="" >
						<div class="t">{{languageData.index_73}}</div>
					</div>
				</template>
			</el-drawer>
			<el-drawer class="messageDrawer1" v-model="messageDrawerDetail" :z-index="9999999" size="480" :modal="false" :show-close="false" :lock-scroll="false">
				<template #header>
					<div class="titleMessage">
						<div class="t">{{languageData.index_70}}</div>
						<img class="i" src="@/assets/i38.png" alt="" srcset="" @click="onClosemessageDrawerDetail()">
					</div>
				</template>
				<template #default>
					<div class="messageDrawerDetail">
						<div class="top">
							<div class="t">{{msgListDataDetails.title}}</div>
							<div class="time">
								<img class="i" src="@/assets/i102.png" alt="" srcset="">
								<div class="tt">{{msgListDataDetails.create_time}}
								</div>
							</div>
						</div>
						<div class="msgInfo">
							<div >
								<pre>{{ msgListDataDetails.content }}</pre>
							</div>
						</div>
					</div>
				</template>
				<template #footer>
					<div class="btnBox2" @click="onClosemessageDrawerDetail()">
						<div class="t">{{languageData.index_74}}</div>
					</div>
				</template>
			</el-drawer>
		</div>
		<div class="loginContent" v-if="showLogin">
			<div class="bg"></div>
			<div class="contentT" :class="loginIdx == 3?'contentT3':''" >
				<div class="box">
					<img class="close" src="@/assets/i38.png" alt="" @click="onshowLogin">
					<div class="fromBox fromBox2" v-if="loginIdx == 3">
						<div class="title">{{formData.source}}</div>
						<div class="inputBox" >
							<div class="input">
								<div class="lable">{{languageData?.index_52}}</div>
								<el-input v-model="formData.company"  autocomplete="off" />
							</div>
							
							<div class="input">
								<div class="lable">{{languageData?.index_54}}</div>
								<el-input v-model="formData.name"  autocomplete="off" />
							</div>
							<div class="input">
								<div class="lable">{{languageData?.index_56}}</div>
								<el-input v-model="formData.position"  autocomplete="off" />
							</div>
							<div class="input">
								<div class="lable">{{languageData?.index_57}}</div>
								<el-input  v-model="formData.email"  autocomplete="off" />
							</div>
							<div class="input">
								<div class="lable">{{languageData?.index_64}}</div>
								<div class="newInput">
									<div class="sBox">
										<el-select-v2
											v-model="formData.country"
											:options="countryList"
											value-key="en"
											limit="3"
											:popper-append-to-body="false"
											:props="{
												label: 'en',
												value: 'en',
											}"
											placeholder="Please select"
										/>
									</div>
									<div class="pbox">
										<div class="yanma2">{{countryList.find((item:any) => item.en == formData.country)?.prefix }}</div>
										<el-input class="codeIpnut2" v-model="formData.phone"  autocomplete="off" />
									</div>
								</div>
								
							</div>
							<div class="input" v-if="formData.source == languageData?.index_27">
								<div class="lable">{{languageData?.index_59}}</div>
								<el-input  v-model="formData.message"  autocomplete="off" />
							</div>
							<div class="input" style="align-items: flex-start;" v-if="formData.source == languageData?.index_27">
								<div class="lable">{{languageData?.index_61}}</div>
								<div class="uploadRef">
									<el-upload ref="uploadRef" v-model:file-list="fileList" :show-file-list="false"  action="/api/Ajax/upload" :on-change="onChange" class="upload-demo" :auto-upload="true" >
										<template #trigger>
											<div class="uploadRef">
												<img class="i" src="@/assets/i125.png" alt="">
												<div class="t">{{languageData?.index_62}}</div>
											</div>
										</template>						
									</el-upload>
									<div class="fileList">
										<div class="list" v-for="(item,index) in fileList" :key="index">
											<img class="i" src="@/assets/i126.png" alt="">
											<div class="t">{{ item.name }}</div>
											<img class="d" src="@/assets/i38.png" alt="" @click.stop="delFile(index)">
										</div>
									</div>
								</div>
							</div>
							
						</div>
						<div class="btnTT" @click="onContact()">{{languageData?.index_51}}</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import { defineComponent,ref,onMounted,onUnmounted,watch,inject } from 'vue';
import {postApi,getApi} from '@/utils/index'
import { useRouter,useRoute } from "vue-router"
import type { UploadProps } from 'element-plus'
import { ElMessage } from 'element-plus'
export default defineComponent({
	name: 'headerComp',
	props: {
		indexNav: Array,
		navTopId: Number,
		positionFixed: Boolean,
		languageData: Object
	},
	setup(props,{ emit }) {
		
		const isBackgroundVisible = ref(false)
		const router = useRouter()
		const routerPath = useRoute()
		const navShow:any = ref(true);
		const threshold = ref(10)
		let userInfo: any = ref({})
		const loginIdx = ref(3)
		const showLogin = ref(false)
		const formData = ref({
			email: '',
			phone: '',
			password: '',
			password2: '',
			code: '',
			company: '',
			position: '',
			name: '',
			phone_prefix: '',
			source: props.languageData?.index_4,
			message: '',
			country: ''
		})
		const countryList:any = ref()
		const fileList:any = ref([])
		const articleUrl = ref(null)
		const onshowLogin = (type:any,url = null,source = '') => {
			articleUrl.value = url
			showLogin.value = !showLogin.value
			loginIdx.value = type || 1
			formData.value = {
				email: '',
				phone: '',
				password: '',
				password2: '',
				code: '',
				company: '',
				position: '',
				name: '',
				phone_prefix: '',
				source: source || props.languageData?.index_4,
				message: '',
				country: ''
			}
		}
		const handleScroll = () => {
			const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      		isBackgroundVisible.value = scrollTop >= threshold.value?true:false
			
		}
		const navIdx:any = ref(0);
		const navChildIdx:any = ref(null);
		

		const searchKey:any = ref('')

		// 一级切换
		const onNav = (item:any,index:any) => {
			navIdx.value = index
			navChildIdx.value = null
			navShow.value = true
			localStorage.setItem('headNavIdx',index)
			localStorage.setItem('headNavChildIdx','')
			localStorage.setItem('headNavObj',JSON.stringify(indexNavTop.value[navIdx.value]))
			if (index == 0) {
				router.push({
					path: '/admin/panel',
					query: {
						id: item.id
					}
				})
				
			} else {
				router.push({
					path: '/admin/panel2',
					query: {
						id: item.id
					}
				})
			}
		}
		// 二级切换
		const onNavChild = (index:any,item:any) => {
			navChildIdx.value = item.id
			localStorage.setItem('headNavChildIdx',item.id)
			if (item.id == null) {
				if (navIdx.value == 0) {
					router.push({
						path: '/admin/panel'
					})
				} else {
					router.push({
						path: '/admin/panel2'
					})
				}
				
			} else if(item && item.id == 20) {
				router.push({
					path: '/admin/productData2',
					query: {
						id: item.id,
						pid: item.pid,
						title: item.title
					}
				})
			}else if(item && item.id == 21 || item.id == 29) {
				router.push({
					path: '/admin/Industrial',
					query: {
						id: item.id,
						pid: item.pid,
						title: item.title
					}
				})
			}else if(item && item.id == 22 || item.id == 30) {
				if (routerPath.path.includes('/admin/IndustrialDataCenter/')) {
					return false
				}
				
				router.push({
					path: '/admin/IndustrialDataCenter/index',
					query: {
						id: item.id,
						pid: item.pid,
						title: item.title
					}
				})
			} else  {
				router.push({
					path: '/admin/productData',
					query: {
						id: item.id,
						pid: item.pid,
						title: item.title
					}
				})
			}
			
			
		}
		// 切换用户信息
		
		const companyPanel = (type:any) => {
			navIdx.value = null
			navChildIdx.value = ''
			localStorage.setItem('headNavIdx','')
			localStorage.setItem('headNavChildIdx','')
			navShow.value = false
			router.push({
				path: '/admin/companyPanel',
				query: {
					type: type
				}
			})
		}
		const searchResults = () => {
			const routeUrl = router.resolve(`/admin/searchResults?keyword=${searchKey.value}`).href
    		window.open(routeUrl, '_blank');
			searchKey.value = ''
		}
		
		const onIndex = () => {
			navIdx.value = null
			navChildIdx.value = ''
			localStorage.setItem('headNavIdx','')
			localStorage.setItem('headNavChildIdx','')
			navShow.value = false
			router.push({
				path: '/',
			})
		}
		const loginOut = async () => {
			let res = await getApi('/api/useren/out',{})
			navIdx.value = null
			localStorage.removeItem('userInfo')
			localStorage.removeItem('headNavObj')
			localStorage.removeItem('headNavChildIdx')
			localStorage.removeItem('headNavIdx')
			document.cookie = "PHPSESSID=";
			router.push({
				path: '/',
			})
			setTimeout(() => {
				window.location.reload()
			}, 500);
		}
		const indexNavTop = ref(<any>[]);
		// 用户信息
		const getUserInfo = async () => {
			let res = await postApi('/api/useren/getUserInfo',{})
			localStorage.setItem('userInfo',JSON.stringify(res.data))
			userInfo.value = res.data
		}
		// 导航
		const indexNav = async () => {
			let res:any = await getApi('/api/define/nav',{})
			indexNavTop.value = res.data
			if (routerPath.path != '/' && routerPath.path != '/articleDetails') {
				localStorage.setItem('headNavObj',JSON.stringify(indexNavTop.value[navIdx.value]))
			} else {
				navIdx.value = null
				navShow.value = false
			}
			
		}
		//消息通知
		const messageDrawer = ref(<any>false); 
		const messageDrawerDetail = ref(<any>false); 
		const pageMsg = ref(<any>1); 
		const totalMsg = ref(<any>1); 
		const totalMsgNo = ref(<any>0); 
		const msgListData = ref(<any>[]); 
		const msgListDataDetails = ref(<any>{}); 
		const languageData = ref(<any>{}); 
			
		const messagesList = async () => {
			let data = {
				page: pageMsg.value
			}
			let res = await getApi('/api/Messages/index',data)
			msgListData.value = msgListData.value.concat(res.data.data)
			totalMsg.value = res.data.total
			totalMsgNo.value = res.data.netread
		}
		const listBoxMsg = ref(<any>null); 
		const handleScrollMsg = (e:any) => {
			if(listBoxMsg.value.wrapRef.scrollHeight - listBoxMsg.value.wrapRef.clientHeight == e.scrollTop){
				if (totalMsg.value > msgListData.value.length) {
					pageMsg.value++
					messagesList()
				}
				
			}
		}
		const getMessageDrawer = (show:any) => {
			messageDrawer.value = show
			if (show) {
				document.body.style.width = 'calc(100% - 17px)'
				document.body.style.overflow = 'hidden'
			} else {
				document.body.style.width = ''
				document.body.style.overflow = ''
			}
		}
		const getmessageDrawerDetail = async (item:any) => {
			let data = {
				id: item.id
			}
			let res = await getApi('/api/Messages/detail',data)
			if (item.is_read == 0) {
				totalMsgNo.value--
			}
			item.is_read = 1
			msgListDataDetails.value = res.data
			messageDrawerDetail.value = true
			document.body.style.width = 'calc(100% - 17px)'
			document.body.style.overflow = 'hidden'
		}
		const msgAllRead = async (item:any) => {
			let res = await getApi('/api/Messages/allread',{})
			pageMsg.value = 1
			msgListData.value = []
			messagesList()

		}
		const onClosemessageDrawerDetail = async () => {
			messageDrawerDetail.value = false
			getMessageDrawer(true)
			
		}
		const messageDrawerClose = async () => {
			messageDrawer.value = false
			document.body.style.width = ''
			document.body.style.overflow = ''
			
		}
		const getCountry = async () => {
	
			let res:any = await postApi('/api/Useren/getCountry',{})
			countryList.value = res.data
		}
		const delFile = async (index:any) => {
			fileList.value.splice(index,1)
		}
		const onChange: UploadProps['onChange'] = (uploadFile, uploadFiles) => {
		
		}
		const onContact = async () => {
			let data:any = {
				email: formData.value.email,
				company: formData.value.company,
				contacts: formData.value.name,
				duties: formData.value.position,
				phone_prefix: countryList.value.find((item:any) => item.en == formData.value.country)?.prefix,
				phone: formData.value.phone,
				source: formData.value.source,
				country: formData.value.country,
				files: []
			}
			if (fileList.value.length > 0) {
				for(let i in fileList.value) {
					data.files.push(fileList.value[i].response.data.url)
				}
				
			}
			let res:any = await postApi('/api/Useren/register',data)
			if (res.code == 0) {
				ElMessage.success(res.msg)
				loginIdx.value = 1
				showLogin.value = false
			} 
		}

		watch(messageDrawer, (newValue, oldValue) => {
			if (newValue) {
				document.body.style.width = 'calc(100% - 17px)'
				document.body.style.overflow = 'hidden'
			} else {
				document.body.style.width = ''
				document.body.style.overflow = ''
			}
		})

		onMounted(() => {
			window.addEventListener('scroll', handleScroll)
			
			languageData.value = JSON.parse(localStorage.getItem('languageData') || '')
			indexNav()
			
			getCountry()
			getUserInfo()
			messagesList()
			let headNavIdxT = localStorage.getItem('headNavIdx') || ''
	
			if (headNavIdxT) {
				navIdx.value = headNavIdxT
			}
			let headNavChildIdxT = localStorage.getItem('headNavChildIdx') || ''
			if (headNavChildIdxT) {
				navChildIdx.value = headNavChildIdxT
			}
		})
		onUnmounted(() => {
			window.removeEventListener('scroll', handleScroll)
		})
		return {
			delFile,
			onChange,
			onContact,
			isBackgroundVisible,
			navIdx,
			searchKey,
			userInfo,
			indexNavTop,
			emit,
			onNav,
			companyPanel,
			onIndex,
			loginOut,
			getMessageDrawer,
			searchResults,
			messageDrawer,
			messageDrawerDetail,
			messageDrawerClose,
			msgListData,
			msgListDataDetails,
			totalMsgNo,
			getmessageDrawerDetail,
			onClosemessageDrawerDetail,
			handleScrollMsg,
			listBoxMsg,
			msgAllRead,
			navChildIdx,
			onNavChild,
			navShow,
			languageData,
			showLogin,
			formData,
			loginIdx,
			onshowLogin,
			countryList,
			fileList
		}
	}
});
</script>

<style scoped lang="scss">
.header{
	position: sticky;
	top: 0;
	left: 0;
	z-index: 100000;
	width: 100%;
	min-width: 1380px;
	margin: auto;
	.content{
		display: flex;
		align-items: center;
		padding: 0 1.5rem;
		height: 3.6rem;
		.rightBox{
			margin-left: auto;
			display: flex;
			align-items: center;
			.userInfo{
				margin-left: 2.5rem;
				position: relative;
				&:hover{
					.moreInfo{
						display: block;
					}
				}
				.tbox{
					display: flex;
					align-items: center;
					font-size: .7rem;
					cursor: pointer;
					height: 4rem;
					
					.i{
						width: .7rem;
						margin-left: .3rem;
					}
				}
				.moreInfo{
					display: none;
					position: absolute;
					top: 3.6rem;
					right: 0;
					z-index: 9;
					background-image: url('@/assets/i53.png');
					background-size: 100% 100%;
					background-repeat: no-repeat;
					width: 20.4rem;
					border-radius: .6rem;
					border: 1px solid rgba(217, 217, 217, 0.2);
					background-color: #fff;
					box-shadow: 0px -5px 8px 0px rgba(255, 255, 255, 0.5),0px 6px 8px 0px rgba(53, 73, 126, 0.02),0px 8px 35px 0px rgba(126, 65, 53, 0.03);
					.box{
						padding: 1rem;
						.out{
							display: flex;
							align-items: center;
							justify-content: center;
							font-size: .7rem;
							cursor: pointer;
							margin-top: 1.5rem;
							padding-top: .5rem;
							border-top: solid 1px #F5F5F5;
							.i{
								width: .8rem;
								margin-right: .4rem;
							}
						}
						.itemBox{
							display: flex;
							align-items: center;
							justify-content: center;
							margin-top: 1rem;
							.item{
								display: flex;
								align-items: center;
								font-size: .7rem;
								position: relative;
								padding: 0 1rem;
								cursor: pointer;
								
								&::after{
									content: '';
									position: absolute;
									width: 1px;
									height: .7rem;
									background-color: #D9D9D9;
									top: 50%;
									right: 0;
									transform: translate(0,-50%);
								}
								.i{
									width: .8rem;
									margin-right: .4rem;
								}
								&:last-child{
									&::after{
										display: none;
									}
								}
							}
						}
						.name{
							text-align: center;
							font-size: .9rem;
							font-weight: 700;
							margin-top: 1.75rem;
						}
						.ttop{
							display: flex;
							align-items: center;
							.i{
								width: .9rem;
							}
							.t{
								font-size: .7rem;
								margin: 0 .5rem;
							}
							.c{
								width: .9rem;
								margin-left: auto;
								cursor: pointer;
							}
						}
					}
				}
			}
			.search{
				display: flex;
				align-items: center;
				background: #F3F3F7;
				border-radius: 100rem;
				width: 10rem;

				:deep(.el-input){
					flex: 1;
					line-height: 1.7rem;
					height: 1.7rem;
					font-size: .7rem;
					--el-input-border: none;
					.el-input__wrapper{
						padding: 0 .5rem 0 1rem;
						background-color: transparent;
						box-shadow: none;
					}
				}
				.i{
					width: .8rem;
					height: .8rem;
					margin-right: 1rem;
					cursor: pointer;
				}
			}
			.btnBox{
				display: flex;
				align-items: center;
				margin-left: 2.5rem;
				.btn{
					display: flex;
					align-items: center;
					font-size: .7rem;
					cursor: pointer;
					height: 4rem;
					position: relative;
					.i{
						width: .8rem;
						margin-right: .3rem;
					}
					.dian{
						position: absolute;
						width: .4rem;
						height: .4rem;
						background-color: #F73100;
						border-radius: 100%;
						top: 1.4rem;
						right: -.4rem;
					}
					.wxCode{
						display: none;
						position: absolute;
						top: 3.6rem;
						left: 50%;
						z-index: 9;
						transform: translate(-50%,0);
						background-color: #fff;
						width: 100%;
						box-shadow: 0px -5px 8px 0px rgba(255, 255, 255, 0.5),0px 6px 8px 0px rgba(53, 73, 126, 0.02),0px 8px 35px 0px rgba(126, 65, 53, 0.03);
						.img{
							width: 100%;
						}
					}
				}
				.btn2{
					&:hover{
						.wxCode{
							display: block;
						}
					}
				}
				.line{
					background: #D8D8D8;
					width: 1px;
					height: .6rem;
					margin: 0 1.2rem;
				}
			}
		}
		.navBox{
			display: flex;
			align-items: center;
			.nav{
				font-size: 1rem;
				color: #1F1F1F;
				position: relative;
				margin-left: 3.6rem;
				cursor: pointer;
				font-weight: 500;
			}
			.active{
				color: #F73100;
				&::after{
					content: '';
					background-image: url('@/assets/i41.png');
					width: .7rem;
					height: .7rem;
					background-size: cover;
					position: absolute;
					bottom: -.7rem;
					left: 50%;
					transform: translate(-50%,0);
				}
			}
		}
		.logo{
			width: 7.6rem;
			height: 1.9rem;
			background-image: url('@/assets/logo.svg');
			background-size:cover;
			background-repeat: no-repeat;
			cursor: pointer;
		}
	
	}
	.navBoxChild{
		padding: 0 1.5rem 0 11.7rem;
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		
		.item{
			font-size: .8rem;
			color: #434343;
			margin-right: 2.1rem;
			cursor: pointer;
			position: relative;
			line-height: 2.6rem;
			z-index: 2;
			&:last-child{
				margin-right: 0;
			}
		}
		.active{
			font-size: .8rem;
			font-weight: 700;
			&::after{
				content: '';
				position: absolute;
				width: 100%;
				bottom: 0;
				background: #F73100;				
				height: 2px;
				left: 0;
				z-index: -1;
			}
		}
	}
	.has-bg2{
		background: linear-gradient(180deg, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 236%), linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 145%), linear-gradient(180deg, #FDF2F2 0%, #FEF5F5 100%);
		box-sizing: border-box;
		
	}
	
	
}
.has-bg{
	background-color: #fff;
	box-shadow: 0px -5px 8px 0px rgba(255, 255, 255, 0.8),0px 8px 35px 0px rgba(126, 65, 53, 0.1);
}
.messageDrawer{
	::-webkit-scrollbar {
		display: none;
	}
	:deep(.el-overlay){
		.el-drawer{
			background-image: url('@/assets/i100.png');
			background-size: cover;
			background-position: top;
			background-repeat: no-repeat;
		}
		// .el-drawer__body{
		// 	padding: 1.5rem;
		// }
		.el-drawer__header{
			padding: 1.8rem 1.5rem;
			margin-bottom: 0;
		}
		.el-drawer__body{
			padding: 0 1.5rem;
		}
	}
	:deep(.el-drawer){
		.el-drawer__header{
			padding: 1.8rem 1.5rem;
			margin-bottom: 0;
		}
		.el-drawer__body{
			padding: 0 1.5rem;
		}
	}
	.titleMessage{
		display: flex;
		align-items: center;
		.t{
			font-size: .9rem;
			font-weight: 700;
			color: #1F1F1F;
			position: relative;
			z-index: 9;
			&::after{
				content: '';
				position: absolute;
				width: 90%;
				bottom: 0;
				background: rgba(247, 49, 0, 0.3);
				height: .5rem;
				left: 50%;
				z-index: -1;
				transform: translate(-50%, 0);
			
			}
		}
		.n{
			font-size: .7rem;
		}
		.i{
			margin-left: auto;
			cursor: pointer;
			width: 1rem;
		}
	}
	.listBox{
		.list{
			display: flex;
			align-items: center;
			margin-bottom: 2rem;
			cursor: pointer;
			&:last-child{
				margin-bottom: 0;
			}
			.imgBox{
				.i{
					width: 2.4rem;
					height: 2.4rem;
				}
			}
			.info{
				flex: 1;
				margin-left: .6rem;
				.name{
					font-size: .8rem;
				}
				.msg{
					font-size: .7rem;
					color: #999999;
					margin-top: 2px;
					display: -webkit-box;
					-webkit-line-clamp: 1;
					-webkit-box-orient: vertical;
					word-break: break-all;
					white-space: normal;
					overflow: hidden;
				}
			}
			.no{
				.name{
					color: #8C8C8C;
				}
			}
		}
	}
}
.messageDrawerDetail{
	.top{
		border-bottom: solid 1px rgba(238, 238, 238, 0.5);
		padding-bottom: .8rem;
		.t{
			font-size: .9rem;
			font-weight: 700;
		}
		.time{
			display: flex;
			align-items: center;
			margin-top: .5rem;
			.i{
				width: .7rem;
			}
			.tt{
				color: #8C8C8C;
				font-size: .6rem;
				margin-left: 2px;
				margin-top: 1px;
			}
		}
	}
	.msgInfo{
		font-size: .7rem;
		color: #8C8C8C;
		line-height: 1.5rem;
		margin-top: .8rem;
	}
}

:deep(.el-drawer__footer){
	text-align: left;
	.btnBox{
		display: inline-flex;
		align-items: center;
		border: 1px solid #D9D9D9;
		margin-right: auto;
		padding: .6rem .8rem;
		cursor: pointer;
		.i{
			width: .9rem;
		}
		.t{
			font-size: .7rem;
			margin-left: 4px;
		}
	}
	.btnBox2{
		display: flex;
		align-items: center;
		border: 1px solid #D9D9D9;
		margin-right: auto;
		padding: .6rem .8rem;
		cursor: pointer;
		.t{
			font-size: .7rem;
			margin-left: 4px;
			flex: 1;
			text-align: center;
		}
	}
}
@media screen and (max-width:1520px) {
	.header {
		.navBoxChild{
			.item{
				margin-right: 1.5rem;
			}
		}
	}
}
.loginContent{
	.bg{
		position: fixed;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.5);
		top: 0;
		left: 0;
		z-index: 10000;
	}
	.contentT{
		position: fixed;
		width: 49.4rem;
		background-color: #fff;
		background-image: url('../assets/adminbg2.png');
		background-repeat: no-repeat;
		background-size: cover;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		z-index: 10001;
		border-radius: .6rem;
		height: 26.9rem;
		display: flex;
		justify-content: center;
		.box{
			padding: 1.8rem;
			flex: 1;
			.close{
				width: 1rem;
				height: 1rem;
				margin-left: auto;
				cursor: pointer;
			}
			.fromBox{
				padding: 1.2rem;
				width: 18.5rem;
				.error{
					font-size: .6rem;
					color: #F73100;
					margin-top: .5rem;
					text-align: center;
					min-height: 1rem;
					line-height: 1rem;
				}
				.msgT{
					font-size: .65rem;
					color: #8C8C8C;
					cursor: pointer;
					margin-top: .5rem;
					text-align: center;
					.span{
						text-decoration: underline;
					}
				}
				.btnT{
					color: #F7FBFE;
					font-size: .75rem;
					font-weight: 500;
					line-height: 2.2rem;
					border-radius: .2rem;
					margin-top: .9rem;
					text-align: center;
					cursor: pointer;
					width: 17.5rem;
					background: linear-gradient(0deg, rgba(247, 49, 0, 0.5), rgba(247, 49, 0, 0.5)), radial-gradient(159% 159% at 29% -68%, rgba(253, 224, 209, 0.5) 26%, rgba(254, 207, 150, 0.19) 58%, rgba(243, 86, 55, 0) 99%), #F34F5A;
				}
				.btnTT{
					color: #F7FBFE;
					font-size: .75rem;
					font-weight: 500;
					line-height: 2.2rem;
					border-radius: .2rem;
					margin-top: 2rem;
					text-align: center;
					cursor: pointer;
					width: 17.5rem;
					background: linear-gradient(0deg, rgba(247, 49, 0, 0.5), rgba(247, 49, 0, 0.5)), radial-gradient(159% 159% at 29% -68%, rgba(253, 224, 209, 0.5) 26%, rgba(254, 207, 150, 0.19) 58%, rgba(243, 86, 55, 0) 99%), #F34F5A;
				}
				.forgotBox{
					display: flex;
					align-items: center;
					margin-top: 1rem;
					.jizhu{
						display: flex;
						align-items: center;
						cursor: pointer;
						line-height: 1;
						.i{
							width: .7rem;
							height: .7rem;
						}
						.t{
							font-size: .65rem;
							margin-left: .3rem;
							color: #8C8C8C;
						}
					}
					.wang{
						margin-left: auto;
						font-size: .65rem;
						color: #8C8C8C;
						cursor: pointer;
					}
				}
				
				.inputBox{
					margin-top: 1.2rem;
					.input{
						width: 100%;
						margin-bottom: .6rem;
						position: relative;
						display: flex;
						align-items: center;
						:deep(.is-focused){
							box-shadow: 0 0 0 1px var(--el-input-hover-border-color) inset;
						}
						:deep(.el-select){
							flex: 1;
							.el-select__wrapper{
								height: 2.4rem;
							}
						}
						.upload-demo{
							.uploadRef{
								background: rgba(247, 49, 0, 0.03);
								border: 1px dashed #F5BBB1;
								width: 6rem;
								height: 6rem;
								border-radius: .2rem;
								display: flex;
								flex-direction: column;
								align-items: center;
								justify-content: center;
								.i{
									width: 1.6rem;
									height: 1.6rem;
								}
								.t{
									font-size: .65rem;
									color: #F73100;
									margin-top: .35rem;
								}
							}
						}
						.fileList{
							flex: 1;
							margin-left: .6rem;
							
							.list{
								display: flex;
								align-items: center;
								background: rgba(0, 0, 0, 0.05);
								padding: 0 .4rem;
								border-radius: .2rem;
								margin-bottom: .3rem;
								height: 1.8rem;
								&:last-child{
									margin-bottom: 0;
								}
								.i{
									width: .8rem;
									height: .8rem;
								}
								.t{
									font-size: .65rem;
									margin-left: .4rem;
									text-overflow: ellipsis;
									display: -webkit-box;
									-webkit-line-clamp: 1;
									-webkit-box-orient: vertical;
									overflow: hidden;
									flex: 1;
									word-break: break-all;
								}
								.d{
									width: .6rem;
									height: .6rem;
									margin-left: .3rem;
									cursor: pointer;
								}
							}
						}
						.yanma2{
							position: absolute;
							font-size: .65rem;
							color: #8C8C8C;
							left: 0;
							top: 50%;
							transform: translate(0,-50%);
							padding-left: 1rem;
							line-height: 1;
							cursor: pointer;
							width: 3.5rem;
							height: 2.4rem;
							display: flex;
							align-items: center;
							z-index: 9;
							&::after{
								content: '';
								position: absolute;
								width: 1px;
								height: .8rem;
								background-color: rgba(140, 140, 140, 0.5);
								left: 3rem;
								top: 50%;
								transform: translate(0,-50%);
							}
						}
						.yanma{
							position: absolute;
							font-size: .65rem;
							color: #8C8C8C;
							right: 1rem;
							top: 50%;
							transform: translate(0,-50%);
							padding-left: 1rem;
							line-height: 1;
							cursor: pointer;
							width: 3.5rem;
							text-align: center;
							&::after{
								content: '';
								position: absolute;
								width: 1px;
								height: 80%;
								background-color: rgba(140, 140, 140, 0.5);
								left: 0;
								top: 50%;
								transform: translate(0,-50%);
							}
						}
						&:last-child{
							margin-bottom: 0;
						}
						.lable{
							font-size: .65rem;
							width: 5rem;
							text-align: justify;
							margin-right: .9rem;
						}
						.uploadRef{
							display: flex;
							flex: 1;
							.upload-demo{
								flex: 0 0 6rem;
							}
						}
						:deep(.el-input){
							flex: 1;
							line-height: 2.4rem;
							height: 2.4rem;
							font-size: .7rem;
							--el-input-hover-border: #8C8C8C;
							--el-input-focus-border: #8C8C8C;
							--el-input-hover-border-color: #8C8C8C;
							--el-input-clear-hover-color: #8C8C8C;
							--el-input-focus-border-color: #8C8C8C;
							--el-input-text-color: #434343;
							--el-input-width: 100%;
							.el-input__wrapper{
								padding: 0 1rem;
							}
						}
						.codeIpnut{
							:deep(.el-input__wrapper){
								padding-right: 35%;
							}
							
						}
						.codeIpnut2{
							:deep(.el-input__wrapper){
								padding-left: 3.5rem;
							}
							
						}
						.newInput{
							display: flex;
							align-items: center;
							flex: 1;
							.sBox{
								flex: 0 0 8rem;
							}
							.pbox{
								flex: 1;
								margin-left: .6rem;
								position: relative;
							}
						}
					}
				}
				.title{
					font-size: 1.3rem;
					font-weight: 700;
					color: #3D3D3D;
				}	
				.navBox{
					display: flex;
					align-items: center;
					margin-top: 1.5rem;
					.t{
						position: relative;
						font-size: .8rem;
						color: #434343;
						margin-right: 3.6rem;
						cursor: pointer;
						&:last-child{
							margin-right: 0;
						}
					}
					.active{
						font-size: .8rem;
						color: rgba(0, 0, 0, 1);
						font-weight: 700;
						&::after{
							content: '';
							position: absolute;
							width: 90%;
							bottom: -.15rem;
							background: radial-gradient(600% 600% at 25% -253%, rgba(255, 214, 200, 0.8) 0%, rgba(254, 217, 186, 0.304) 58%, rgba(255, 184, 170, 0) 97%), #FF7070;
							height: .5rem;
							left: 50%;
							z-index: -1;
							border-radius: .2rem;
							opacity: 0.6;
							transform: translate(-50%,0);
						}
					}

				}
			}
			.fromBox2{
				width: 20rem;
			}
		}
	}
	.contentT3{
		height: auto;
		background-image: url('../assets/adminbg3.png');
		width: 56.5rem;
		.box {
			.fromBox2{
				width: 26.2rem;
			}
		}
	}
}
</style>
