<template>
	<router-view />
	<!-- cookie -->
	<div class="cookieBox" v-if="cookieBox">
		<div class="box">
			<div class="left">
				<div class="title">Cookies</div>
				<div class="msg">By clicking "Accept All Cookies", you agree to the storing of cookies on your device to enhance site navigation, analyze site usage, and assist in our marketing efforts.</div>
			</div>
			<div class="right">
				<div class="btn" @click="acceptCookie">Accept</div>
				<div class="btn" @click="rejectCookie">Reject</div>
			</div>
		</div>
	</div>
</template>
<script lang="ts">
import { defineComponent,ref,onMounted,onUnmounted,watch } from 'vue';
export default defineComponent({
	setup() {
		const cookieBox:any = ref(false)
		const getCookie = (name:any) => {
			var nameEQ = name + "=";
			var ca = document.cookie.split(';');
			for(var i = 0; i < ca.length; i++) {
				var c = ca[i];
				while (c.charAt(0) == ' ') c = c.substring(1, c.length);
				if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
			}
			return null;
		}
		
		const setCookie = (name:any, value:any, days:any) => {
			var expires = "";
			if (days) {
				var date = new Date();
				date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
				expires = "; expires=" + date.toUTCString();
			}
			document.cookie = name + "=" + (value || "") + expires + "; path=/";
		}
		const acceptCookie = () => {
			setCookie("cookieSet", "accept", 365)
			cookieBox.value = false
		}
		const rejectCookie = (name:any) => {
			setCookie(name, "", -1)
			cookieBox.value = false
		}
		onMounted(() => {
			if (getCookie("cookieSet") === null) {
				cookieBox.value = true
			}
			

		})
		return {
			cookieBox,
			acceptCookie,
			rejectCookie
		}
	}
})
</script>
<style lang="scss">
.cookieBox{
	position: fixed;
	z-index: 999999;
	bottom: 0;
	left: 0;
	background: #FFFFFF;
	box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.07);
	width: 100%;
	.box{
		padding: 1.4rem 8rem;
		max-width: 1600px;
		display: flex;
		align-items: center;
		.right{
			display: flex;
			align-items: center;
			.btn{
				color: #F73100;
				font-size: 0.8rem;
				padding: 0.4rem 3.2rem;
				border: solid 1px #F73100;
				border-radius: 0.2rem;
				cursor: pointer;
				margin-left: .4rem;
			}
		}
		.left{
			flex: 1;
			.title{
				font-size: .8rem;
				color: #1F1F1F;
				font-weight: 500;
				font-family: 'HarmonyOS_Sans_SC_Bold';
			}
			.msg{
				font-size: .7rem;
				color: #434343;
				margin-top: .2rem;
				
			}
		}
	}
}
</style>
<style lang="scss">
@font-face {
    font-family: '400Regular';
    src: url('@/assets/font/D-DIN-PRO-400-Regular.ttf');
}
@font-face {
    font-family: '500Medium';
    src: url('@/assets/font/D-DIN-PRO-500-Medium.ttf');
}
@font-face {
    font-family: '600SemiBold';
    src: url('@/assets/font/D-DIN-PRO-600-SemiBold.ttf');
}
@font-face {
    font-family: '700Bold';
    src: url('@/assets/font/D-DIN-PRO-700-Bold.ttf');
}
@font-face {
    font-family: '800ExtraBold';
    src: url('@/assets/font/D-DIN-PRO-800-ExtraBold.ttf');
}
@font-face {
    font-family: '900Heavy';
    src: url('@/assets/font/D-DIN-PRO-900-Heavy.ttf');
}
@font-face {
    font-family: 'SourceHanSansCN';
    src: url('@/assets/font/SourceHanSansCN-Normal.otf');
}
@font-face {
    font-family: 'SourceHanSansCNMedium';
    src: url('@/assets/font/SourceHanSansCN-Medium.otf');
}
@font-face {
    font-family: 'HarmonyOS_Sans_SC_Bold';
    src: url('@/assets/font/HarmonyOS_Sans_SC_Bold.subset.ttf');
}
@font-face {
    font-family: 'HarmonyOS_Sans_SC_Medium';
    src: url('@/assets/font/HarmonyOS_Sans_SC_Medium.subset.ttf');
}
@font-face {
    font-family: 'HarmonyOS_Sans_SC_Regular';
    src: url('@/assets/font/HarmonyOS_Sans_SC_Regular.subset.ttf');
}


body,html {
	font-family: 'HarmonyOS_Sans_SC_Regular';
	padding: 0;
	margin: 0;
	color: #1F1F1F;
	font-weight: 400
}


img{
	display: block;
}
.el-message{
	z-index: 100000 !important;
}
input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
input[type="number"]{
    -moz-appearance: textfield;
}
@media screen and (min-width:1680px) {
	body,html {
		font-size: 20px;
	}
}
@media screen and (max-width:1680px) and (min-width:1400px) {
	body,html {
		font-size: 16px;
	}
}
@media screen and (max-width:1400px) and (min-width:1200px) {
    
    body,html {
        font-size: 14px;
    }
}
.noData{
	padding: 10rem 0;
	.i{
		width: 11.7rem;
		margin: auto;
	}
	.t{
		font-size: .7rem;
		color: #999999;
		text-align: center;
	}
}
.noData2{
	padding: 3rem 0;
}
.red{
	color: #009965 !important;
}
.green{
	color: #F73100 !important;
}
:root{
	--el-color-primary: #F73100 !important;
    --el-color-primary-light-3: rgba(247, 49, 0, 0.5) !important;
	--el-color-primary-light-5: rgba(247, 49, 0, 0.4) !important;
    --el-color-primary-light-7: rgba(247, 49, 0, 0.3) !important;
    --el-color-primary-light-8: rgba(247, 49, 0, 0.2) !important;
    --el-color-primary-light-9: rgba(247, 49, 0, 0.1) !important;
	--el-color-primary-dark-2: #F73100 !important;
	--el-font-size-base: .7rem !important;
	--el-text-color-regular: #434343 !important;
	--el-input-hover-border-color: #8C8C8C !important;
}
.el-cascader-menu__wrap.el-scrollbar__wrap{
  height: auto !important;
  max-height: 204px;
}
.el-table__body tr.hover-row > td {
    background: none !important;
}
pre{
  font-family: "Noto Sans SC";
}
.el-popper{
  z-index: 9999999 !important;
}
</style>
